<template>
    <div>
        <app-table
            :columns="columns"
            :actions="tableActions"
            url="services"
            ref="table"
            :params="{ groups: ['voice'] }"
            item-preview
        >
            <template v-slot:item.rate="{ item }">
                {{ item.amount_with_tax_for_human }}
                <span v-if="item.period > 1">/ {{ item.period }} months</span>
            </template>
        </app-table>
        <usage-modal />
    </div> 
</template>
<script>
import AppTable from '@/components/Table.vue';

export default {
    components: {
        AppTable,
        UsageModal: () => import('./modals/Usage.vue'),
    },
    data() {
        return {
            columns: [                    
                {
                    text: 'Name',
                    value: 'name',
                    sortable: false,
                    width: 250,
                },
                {
                    text: 'Price',
                    value: 'rate.price_for_human',
                    slotName: 'item.rate',
                    sortable: false,
                    width: 250,
                },
                {
                    text: 'Quantity',
                    value: 'quantity',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Next Invoice',
                    value: 'next_invoice_from_for_human',
                    sortable: false,
                    width: 150,
                },
                {
                    text: 'Status',
                    value: 'status',
                    sortable: false,
                    width: 100,
                },
            ],
            tableActions: [
                {
                    label: 'Usage',
                    icon: 'fal fa-analytics fa-fw',
                    handler: (service) => {
                        this.$router.push({
                            name: 'services.voice.usage',
                            params: {
                                serviceId: service.id,
                            }
                        });
                    }
                },
            ]
        }
    },
}
</script>
